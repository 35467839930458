import React, { useState } from "react"
import { Link } from "react-scroll"
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md"
import "./HeroStyles.css"

const Hero = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  let iconNoHover = { color: "white", fontSize: "1.25rem", marginLef: "8px" }
  let iconHover = { color: "black", fontSize: "1.25rem", marginLef: "8px" }

  return (
    <div id="#home" className="hero-container">
      <div className="hero-bg">
        <div className="img-bg"></div>
      </div>
      <div className="hero-content">
        <div className="heading-h1">Get A New Website For Your Company</div>
        <div className="subtitle-light">
          Sign up for a new website <span>today</span> and receive $100 in credits toward your next payments
        </div>
        <div className="button-wrapper">
          <Link className="button" onMouseEnter={onHover} onMouseLeave={onHover} to="#services" activeClass="active" spy={true} smooth={true} offset={-70} duration={500}>
            Get Started {hover ? <MdArrowForward style={iconHover} /> : <MdKeyboardArrowRight style={iconNoHover} />}{" "}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
