import React from "react"
import Image1 from "../../images/svg-2.svg"
import Button from "../Button/Button"

import "./AboutUsStyles.css"

const AboutUs = () => {
  return (
    <div>
      <div id="#about" className="about-container">
        <div className="wrapper">
          <div className="row">
            <div className="col1">
              <div className="text-wrapper">
                <div className="top-line">About Us</div>

                <div className="h2-heading-light">We love servicing companies like yours</div>
                <div className="subtitle-light">Our goal is to bring quality websites for companies of all sizes. Together, we will build a great web presence.</div>
                <div className="btn-wrap">
                  <Button />
                </div>
              </div>
            </div>
            <div className="col2">
              <div className="img-wrap">
                <img src={Image1} alt=" Us" className="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
