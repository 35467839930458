import React, { useState } from "react"
import { Link } from "react-router-dom"
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md"
import "./SignUpStyles.css"

const Signup = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  let iconNoHover = { color: "white", fontSize: "1.25rem", marginLef: "8px" }
  let iconHover = { color: "black", fontSize: "1.25rem", marginLef: "8px" }

  return (
    <div id="#home" className="signup-container">
      <div className="signup-bg">
        <div className="img-bg"></div>
      </div>
      <div className="signup-content">
        <div className="heading-h1">Page Under Construction!</div>
        <div className="subtitle-light">
          <span>Please</span>, come back later for a new improved Sign Up page.
        </div>
        <div className="button-wrapper">
          <Link className="button" onMouseEnter={onHover} onMouseLeave={onHover} to="/" activeClass="active" spy={true} smooth={true} offset={-70} duration={500}>
            Go Back {hover ? <MdArrowForward style={iconHover} /> : <MdKeyboardArrowRight style={iconNoHover} />}{" "}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Signup
