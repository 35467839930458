import * as Yup from "yup"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(50, "This first name is too Long!").required("A first name is required!"),
  lastName: Yup.string().min(2, "This last name is too short!").max(50, "This last name is too Long!").required("A last name is required!"),
  email: Yup.string().email("Please enter a valid email!").required("An email address is required!"),
  phone: Yup.string().min(10, "This phone number is too short!").max(20, "This phone number is too Long!").required("A phone number is required!"),
  message: Yup.string().required("Sorry, but a message is required!")
})

export default validationSchema
