import React, { useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./App.css"

import Navbar from "./components/Navbar"

import Home from "./pages/Home"
// import Services from "./pages/Services"
// import About from "./pages/About"
// import Contacts from "./pages/Contacts"
import Signup from "./pages/SignUp"

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <BrowserRouter>
        <Navbar isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/" exact element={<Home />} />
          {/* <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="contacts" element={<Contacts />} />*/}
          <Route path="sign-up" element={<Signup />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
