import React from "react"
import AboutUs from "../components/AboutUs"
import ContactUs from "../components/Form"
import Hero from "../components/Hero"
import Pricing from "../components/Princing"
import Services from "../components/Services"
import Footer from "../components/Footer"

const Home = () => {
  return (
    <>
      <Hero />
      <Services />
      <AboutUs />
      <Pricing />
      <ContactUs />
      <Footer />
    </>
  )
}

export default Home
