import React from "react"

import "./FooterStyles.css"

const Footer = () => {
  return (
    <>
      <div className="container-footer">
        <div className="footer-wrapper">
          <div className="footer-1-wrapper">
            <div className="footer-link-items">
              <h3 className="footer-titles">What we do</h3>
              <p className="footer-texts">We provide a web presence of quality for small and medium companies. We also are affordable, dependable, and reliable. Count on us for your next project. </p>
            </div>
          </div>
          <div className="footer-1-wrapper">
            <div className="footer-link-items">
              <h3 className="footer-titles">The Services</h3>
              <p className="footer-texts">Website Design</p>
              <p className="footer-texts">Website Development</p>
              <p className="footer-texts">Web Phone (VOIP)</p>
            </div>
          </div>
          <div className="footer-1-wrapper">
            <div className="footer-link-items">
              <h3 className="footer-titles">Work For Us</h3>
              <p className="footer-texts">We are hiring. Send us an email and we will contact you soon.</p>
            </div>
          </div>
          <div className="footer-1-wrapper">
            <div className="footer-link-items">
              <h3 className="footer-titles">Our Contacts</h3>
              <p className="footer-texts">
                Phone:{" "}
                <a className="madeby-link" href="tel:123-456-7890">
                  +1 214-759-0245
                </a>
              </p>
              <p className="footer-texts">
                Email:{" "}
                <a className="madeby-link" href="mailto: contact@wemeanweb.com" target="_blank" rel="noreferrer">
                  contact@wemeanweb.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="copyright"> © 2021 WeMeanWeb. All rights reserved. </div>
        <div className="madeby">
          Website made by_
          <a className="madeby-link" href="wwww.wemeanweb.com">
            {"  "}
            WeMeanWeb
          </a>
        </div>
      </div>
    </>
  )
}

export default Footer
