import "./FormStyles.css"
import { useState } from "react"
import { Formik, Field } from "formik"
import validationSchema from "./validationSchema"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function MyForm() {
  const [status, setStatus] = useState(undefined)

  return (
    <Formik
      initialValues={{ firstName: "", lastName: "", email: "", phone: "", message: "", textMessage: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact",
            ...values
          })
        })
          .then(() => {
            // alert("Success!")
            setStatus({ type: "success" })
            setSubmitting(false)
          })
          .catch(error => {
            // alert("Error: Please Try Again!")
            setStatus({ type: "error", error })
            setSubmitting(false)
          })
        console.log(values)
        resetForm({ values: "" })
      }}
      // render={({ touched, errors, isSubmitting, handleSubmit, handleReset }) => (
      render={({ touched, errors, isSubmitting, handleSubmit }) => (
        <div className="formWrapper">
          <div className="form-title">Let's Talk</div>
          <div className="inputs">
            {/* <form name="contact" onSubmit={handleSubmit} onReset={handleReset} data-netlify="true" data-netlify-honeypot="bot-field"> */}
            <form name="contact" onSubmit={handleSubmit} data-netlify="true" data-netlify-honeypot="bot-field">
              <div className="field-group">
                <label htmlFor="firstName" className="field-label">
                  First name
                  {touched.firstName && errors.firstName && <p className="error-message">{errors.firstName}</p>}
                </label>
                <Field className="field-input" type="text" name="firstName" />
              </div>
              <div className="field-group">
                <label htmlFor="lastName" className="field-label">
                  Last name
                  {touched.lastName && errors.lastName && <p className="error-message">{errors.lastName}</p>}
                </label>
                <Field className="field-input" type="text" name="lastName" />
              </div>
              <div className="field-group">
                <label htmlFor="email" className="field-label">
                  Email
                  {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                </label>
                <Field className="field-input" type="email" name="email" />
              </div>
              <div className="field-group">
                <label htmlFor="phone" className="field-label">
                  Phone number
                  {touched.phone && errors.phone && <p className="error-message">{errors.phone}</p>}
                </label>
                <Field className="field-input" type="text" name="phone" />
              </div>
              <div className="field-group">
                <label htmlFor="message" className="field-label">
                  Message
                  {touched.message && errors.message && <p className="error-message">{errors.message}</p>}
                </label>
                <Field className="field-input textarea-input" name="message" component="textarea" rows="4" />
              </div>
              <div className="checkbox-group">
                <div className="checkbox-label">
                  <label htmlFor="textMessage">Check here to agree receiving text messages</label>
                </div>
                <Field className="checkbox-field" type="checkbox" name="textMessage" />
              </div>
              {status?.type === "success" && <p className="email-sent">Your email was successfully sent</p>}
              {status?.type === "error" && <p>Email not sent. Please, try again later.</p>}
              <div className="btn-wrapper">
                <input name="submit" type="submit" disabled={isSubmitting} value="Send Message" className="form-btn" />
                {/* <input type="reset" value="Clear Form" className="form-btn" /> */}
              </div>
            </form>
          </div>
        </div>
      )}
    />
  )
}
