import React from "react"

import MyForm from "./MyForm"

import "./FormStyles.css"

const ContactUs = () => {
  return (
    <div id="#contact" className="contact-container">
      <div className="wrapper">
        <div className="row">
          <div className="col1">
            <div className="text-wrapper">
              <div className="top-line">Contact Us</div>
              <div className="h2-heading-dark">We'd love hearing from you</div>
              <div className="subtitle-dark">And it is only the begining of a great relationship. </div>
            </div>
          </div>
          <div className="col2">
            <div className="img-wrap">
              <MyForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
