import React from "react"
import Image1 from "../../images/svg-1.svg"
import Button from "../Button/Button"

import "./ServicesStyles.css"

const ServicesSection = () => {
  return (
    <div id="#services" className="serv-container">
      <div className="wrapper">
        <div className="row">
          <div className="col">
            <div className="text-wrapper">
              <div className="top-line">Premium Websites</div>
              <div className="h2-heading-dark">Accessible website for your company</div>
              <div className="subtitle-dark">Get access to a quality webiste for your company that fits in your budget with no sweat.</div>
              <div className="btn-wrap">
                <Button />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="img-wrap">
              <img src={Image1} alt="Services" className="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesSection
