import React from "react"
import Image3 from "../../images/svg-3.svg"
import Button from "../Button/Button"

import "./PricingStyles.css"

const Pricing = () => {
  return (
    <div>
      <div id="#pricing" className="serv-container">
        <div className="wrapper">
          <div className="row">
            <div className="col">
              <div className="text-wrapper">
                <div className="top-line">Let's talk pricing</div>

                <div className="h2-heading-dark">Fair pricing for small companies</div>
                <div className="subtitle-dark">We believe companies of all sizes should have a great web presence.</div>
                <div className="subtitle-dark">Then, we teamate with small companies to make hight quality websites with low cost for them.</div>
                <div className="btn-wrap">
                  <Button />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="img-wrap">
                <img src={Image3} alt="Pricing" className="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
