import React, { useState } from "react"
import { Link as LinkR } from "react-router-dom"
import { Link as LinkS } from "react-scroll"
import { Button } from "../Button/Button"
import hamburger from "../../images/menu-1.svg"
import xmark from "../../images/x-mark-1.svg"

import "./NavbarStyles.css"

const Navbar = () => {
  const [click, setClick] = useState(false)
  const [navbar, setNavbar] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const changeBackground = () => {
    if (window.scrollY >= 700) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener("scroll", changeBackground)

  return (
    <nav className={navbar ? "navbar active" : "navbar"}>
      <LinkR className="navbar-logo" to="/" onClick={closeMobileMenu}>
        We Mean Web
      </LinkR>
      <div className="menu-icon" onClick={handleClick}>
        {click ? <img src={xmark} alt="X Mark" className="menu-1" /> : <img src={hamburger} alt="Menu" className="menu-1" />}
      </div>
      <div className={click ? "nav-menu active" : "nav-menu"}>
        <div className="nav-item">
          <LinkS className="nav-links" to="#home" onClick={closeMobileMenu} spy={true} smooth={true} offset={-80} duration={500}>
            Home
          </LinkS>
        </div>

        <div className="nav-item">
          <LinkS className="nav-links" to="#services" onClick={closeMobileMenu} spy={true} smooth={true} offset={-80} duration={500}>
            Services
          </LinkS>
        </div>

        <div className="nav-item">
          <LinkS className="nav-links" to="#about" onClick={closeMobileMenu} spy={true} smooth={true} offset={-80} duration={500}>
            About
          </LinkS>
        </div>
        <div className="nav-item">
          <LinkS className="nav-links" to="#pricing" onClick={closeMobileMenu} spy={true} smooth={true} offset={-80} duration={500}>
            Pricing
          </LinkS>
        </div>
        <div className="nav-item">
          <LinkS className="nav-links" to="#contact" onClick={closeMobileMenu} spy={true} smooth={true} offset={-80} duration={500}>
            Contact
          </LinkS>
        </div>
        <div>
          <LinkR className="nav-links-mobile" to="signup" onClick={closeMobileMenu}>
            Sign Up
          </LinkR>
        </div>
      </div>
      <div className="nav-button">
        <Button />
      </div>
    </nav>
  )
}

export default Navbar
